<template>
  <div>
    <paginated-table
      ref="clustersTable"
      :object-actions-if="true"
      :query="{ org_id: curUser.org_id, hidden: false, last_seen:  listClustersTimestamp()(onlyActive) }"
      actions-button-align="middle"
      collection-url="/api/v1/system"
      name-plural="clusters"
      name-singular="cluster"
    >
      <template v-slot:before-results>
        <li class="list-group-item">
          <div class="lead" style="margin-top: 1rem; margin-bottom: 1rem">
            Hey {{ curUser.display_name }} 👋
          </div>
          <div style="color: #888">
            These are your {{ onlyActive ? 'recently active' : ''}} clusters:
          </div>
            <div style="color: #888;display: flex">
                <input
                    v-model="onlyActive"
                    style="margin-right: 5px;margin-top: 2px; cursor: pointer"
                    type="checkbox"
                />
                <div style='margin-top: 3px'>
                    Only show active clusters
                </div>
            </div>
        </li>
      </template>

      <template v-slot:object="props">
        <cluster-row :cluster="props.object"></cluster-row>
      </template>

      <template v-slot:object-actions="props">
        <li>
          <a @click.prevent="$refs.clusterDetails.show(props.object)">
            Cluster Details
          </a>
        </li>
        <template v-if="!isAlive(props.object)">
          <li class="divider"></li>
          <li>
            <a @click.prevent="$refs.hideCluster.show(props.object)">
              Hide Cluster
            </a>
          </li>
        </template>
      </template>

      <template v-slot:no-results>
        <div class="text-center">
          <p class="lead" style="margin-top: 1rem">
            Hey {{ curUser.display_name }} 👋
          </p>
          <p>It looks like you don't have any active WEKA clusters yet.</p>
          <p>Click the button and deploy your first cluster!</p>
          <p>
            <a
              class="btn btn-lg btn-success"
              href="https://start.weka.io"
              target="_blank"
            >
              Deploy Your First Cluster
            </a>
          </p>
          <p class="small">
            <a
              href="https://docs.weka.io/planning-and-installation/aws/weka-installation-on-aws-using-the-cloud-formation/self-service-portal"
              target="_blank"
            >
              <font-awesome-icon
                :icon="['fas', 'arrow-up-right-from-square']"
                class="fa-fw"
              ></font-awesome-icon>
              Learn how automatic deployments work
            </a>
          </p>
        </div>
      </template>
    </paginated-table>

    <cluster-details ref="clusterDetails" />
    <hide-cluster ref="hideCluster" @done="reload" />
  </div>
</template>

<script>
import { ACTIVE_CLUSTERS_MINUTES, listClustersTimestamp } from '@/utils.js';
import moment from "moment";
import ClusterDetails from "./cluster-details.vue";
import HideCluster from "./hide-cluster.vue";

function initialData() {
    return {
        onlyActive: true,
    }
}
export default {
  name: "greeting",

  components: {
    ClusterDetails,
    HideCluster,
  },
    data: initialData,

  computed: {
    curUser() {
      return this.$store.getters.currentUser;
    },
  },

  methods: {
      listClustersTimestamp() {
          return listClustersTimestamp
      },
    isAlive(cluster) {
      return (
        cluster.last_seen &&
        moment().diff(moment(cluster.last_seen), "minutes") < ACTIVE_CLUSTERS_MINUTES
      );
    },
    reload() {
      this.$refs.clustersTable.reload();
    },
  },
};
</script>
